import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import i18n from '@/libs/i18n'
import useCommon from '@/views/organization/useCommon'
import realmConnection from '@/views/habit/realm'

export default function useRoleList() {
  const { showSuccessMessage, showErrorMessage } = useNotifications()
  const { handleError } = useCommon()
  const { invokeFunction, updateItem, updateItems } = realmConnection()
  const { client = null, role = null } = store.state?.userStore?.userData
  const roles = ref([])
  const collection = 'role'

  // Table Headers
  const tableColumns = computed(() => {
    return [
      { key: 'code', label: i18n.t('message.tableHeader.code'), sortable: true },
      { key: 'name', label: i18n.t('message.tableHeader.name'), sortable: true },
      { key: 'description', label: i18n.t('message.tableHeader.description'), sortable: true },
      { key: 'actions', label: i18n.t('message.tableHeader.actions') },
    ]
  })
  const totalRoles = ref(0)
  const perPage = ref(10)
  const currentPage = ref(1)
  const perPageOptions = [5, 10, 15, 20, 25, 30, 40, 50, 100]
  const searchQuery = ref('')
  const defaultSortBy = 'code'
  const sortBy = ref(defaultSortBy)
  const isSortDirDesc = ref(false)
  const isLoading = ref(true)

  const fetchRoles = async () => {
    isLoading.value = true

    try {
      const input = {
        limit: perPage.value,
        total: currentPage.value === 1,
        offset: (currentPage.value * perPage.value) - perPage.value,
        query: {},
        search: searchQuery.value,
        sortBy: sortBy.value || defaultSortBy,
        sortOrder: isSortDirDesc.value ? -1 : 1,
        client_id: (role !== 'admin') ? client.$oid : null,
      }
    
      const items = await invokeFunction({ name: 'rolesQueryWithOffset', arg: input })

      roles.value = items?.roles || []
      if (currentPage.value === 1) totalRoles.value = items?.totalRecords || 0
    } catch (error) {
      console.log(error)
      handleError({ error, defaultMessage: i18n.t('message.role_list_fetch_error') })
    } finally {
      isLoading.value = false
    }
  }

  const deleteRole = async (id) => {
    try {
      const query = { _id: id }
      const action = { $set: { deleted: true } }

      await updateItem({ collection, query, action })

      fetchRoles()
      showSuccessMessage(i18n.t('message.role_remove'))

      // Delete confirmation plannings associated to this role
      await updateItems({ collection: 'confirmation_planning', query: { role: id }, action })
    } catch (error) {
      console.log(error)
      showErrorMessage(i18n.t('message.role_remove_error'))
    }
  }

  const showingMessage = computed(() => {
    const from = ((currentPage.value * perPage.value) - perPage.value) + (roles.value.length ? 1 : 0)
    const to = roles.value.length + ((currentPage.value * perPage.value) - perPage.value)

    return i18n.tc('message.paginationText', 0, { from: from, to: to, total: totalRoles.value })
  })

  watch([currentPage, perPage], () => {
    fetchRoles()
  })

  watch([searchQuery, sortBy, isSortDirDesc], () => {
    currentPage.value = 1
    fetchRoles()
  })

  return {
    fetchRoles,
    tableColumns,
    perPage,
    currentPage,
    perPageOptions,
    totalRoles,
    showingMessage,
    searchQuery,
    sortBy,
    isSortDirDesc,
    roles,
    deleteRole,
    isLoading,
  }
}
